import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Button, SSOOptions } from "../../../components";
import {
  Form,
  FormControl,
  Input,
  Validators,
  useForm,
} from "../../../components/ReactiveForm";
import { ROUTES } from "../../../constants";
import AuthLayout from "../../../layouts/AuthLayout";
import {
  AuthService,
  setHeaders,
  MessageBoxService,
  ToastService,
} from "../../../services";
import { isAuthenticated } from "../../../utils/helpers";

const Login = () => {
  const [form] = useForm<{
    email: FormControl;
    password: FormControl;
  }>({
    email: new FormControl("", [Validators.required(), Validators.email()]),
    password: new FormControl("", [Validators.required()]),
  });

  useEffect(() => {
    isAuthenticated((is_logged_in) => {
      if (is_logged_in) window.location.href = ROUTES.DASHBOARD;
    });
  }, []);

  const onLogin = (formData) => {
    AuthService.csrf().then(({ csrf }) => {
      setHeaders({ "X-CSRFToken": csrf });
      AuthService.login(formData.email, formData.password)
        .then(() => {
          window.location.href = ROUTES.DASHBOARD;
        })
        .catch((err) => {
          ToastService.error("Login failed");
        });
    });
  };

  const onForgotPassword = () => {
    MessageBoxService.confirm({
      type: "email",
      title: "Reset Your Password",
      message: <b>Please enter your email address</b>,
      confirmMessage:
        "A link will be sent via email for you to reset your password",
      confirmButtonText: "Send Email",
    }).then((email) => {
      if (email) {
        AuthService.reset(email)
          .then(() => {
            ToastService.success(
              "A link will be sent via email for you to reset your password. Please check your inbox."
            );
          })
          .catch((err) => {
            ToastService.showHttpError(err, "Login failed");
          });
      }
    });
  };

  const ssoSection = (
    <div className="flex flex-col">
      <SSOOptions />
      <div className="flex flex-row items-center tablet-landscape:justify-between justify-center pb-4">
        <span className="text-md text-white w-55 font-semibold mt-4 text-center tablet-landscape:text-left">
          Or log in using
        </span>
        <div className="hidden tablet-landscape:block border-b border-white w-full mt-4" />
      </div>
    </div>
  )

  return (
    <AuthLayout contentClass="w-85">
      <Form formGroup={form} onSubmit={onLogin}>
        <div className="w-full flex flex-col justify-between">
          <h1 className="tablet-landscape:text-22p text-28p text-white font-bold mb-4 tablet-landscape:text-left text-center ">
            Log into your account
          </h1>
          <div className="mt-10">
            <Input
              control={form.controls.email}
              placeholder="Email"
              inputClass="!bg-white"
              containerClass="!-mt-10"
              fullWidth
              lpIgnore={false}
            />
            <Input
              control={form.controls.password}
              type="password"
              inputClass="!bg-white"
              containerClass="-mt-2"
              placeholder="Password"
              fullWidth
              lpIgnore={false}
            />

            <Button
              type="submit"
              color="primary"
              className="w-full rounded-full shadow-md -mt-2"
            >
              Log In
            </Button>
          </div>
          <div className="text-center mt-10">
            <span
              data-cy="open-forgot-password-modal"
              className="text-white text-md font-bold hover:underline cursor-pointer"
              onClick={onForgotPassword}
            >
              I forgot my password
            </span>
          </div>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default Login;
